@import "../_vars";
div.popup-content {
  & > div.reactContainer {
    padding: 20px;
    border: 10px solid $tvg;
    margin: -10px!important;
    form  label > span {
      display: inline-block;
      width: 200px; }
    form div.react-datepicker-wrapper {
      margin-left: -3px; } }


  .closeButton {
    cursor: pointer;
    display: block;
    right: 17px;
    top: -16px;
    font-size: 30px;
    position: absolute;
    svg {
      //padding: 2px
      position: fixed;
      background-color: white;
      border-radius: 17px;
      border: 2px solid $tvg; } } }

div.MyDialogPaper {
  border: 10px solid $tvg;
  border-radius: 0;
  padding: 20px;
  table {
    width: 100%; }
  @media all and (max-width: 700px) {
    padding: 10px!important;
    margin: 10px;
    .centercontent {
      padding: 0!important;
      width: calc(100% - 0px);
      h2 {
        font-size: 20px; } } }
  //padding-bottom: 60px
  width: 1000px;
  .closeButton {
    cursor: pointer;
    display: block;
    right: 12px;
    top: -22px;
    font-size: 30px;
    position: absolute;
    svg {
      //padding: 2px
      position: fixed;
      background-color: white;
      border-radius: 17px;
      border: 2px solid $tvg; } } }
div.reactSelectContainer {

  //div.MaxSelect__menu
 }  //position: fixed
.MaxSelect__control, .MaxSelect__menu {
  border-radius: 0!important; }
.MaxSelect__control {
  padding: 2px 6px 3px 6px;
  margin-bottom: 5px; }

.MaxSelect__menu {
  z-index: 10000000000000!important;
  overflow: auto!important;
  position: absolute!important; }
.MaxSelect__option--is-focused {
  color: $fontColor!important; }

.MaxSelect__placeholder, input::placeholder {
  //font-family: $font!important
  color: #b4b4b4 !important;
  font-weight: 500!important;
  font-size: 15px!important; }


