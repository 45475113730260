
@import "../_vars.sass";
#mainContainer {
  .blockMenu {
    //float: right
    //margin-top: -62px
    button.rightmost {
      margin-right: 0!important; } }

  .blockContainer {
    &.Video, &.Document {
      &:not(.editMode) {
        display: inline-block; } }
    &.Video {
      @media all and (min-width: 440px) {
        padding-right: 10px; }
      padding-bottom: 7px;
      img,  iframe {
        max-width: 100%;
        @media all and (max-width: 440px) {
          height: 240px; }
        @media all and (max-width: 380px) {
          height: 200px; } }
      div.playbutton {
        position: absolute;
        margin-top: -170px;
        text-align: center;
        width: 100%;
        border: 2px solid red;
        font-size: 60px; }
 }      //margin-left: 200px

    &.Document a {
      display: block;
      text-align: center;
      width: 180px;
      padding: 10px;
      padding-left: 0;
      margin-left: -10px;
      border-bottom: none!important; }
    &.Text {
      img {
        max-width: 100%;
        height: auto; } }

    &.editMode:not(:last-of-type) {
      border-bottom: 2px solid $tvg;
      margin-bottom: 20px; }
    .blockMover {
      float: right;
      cursor: pointer;
      svg {
        margin-left: 5px; } } } }
div.MuiPaper-root {
  iframe {
    max-width: 100%;
    @media all and (max-width: 400px) {
      height: 240px; } } }
div.MuiDialog-paperFullScreen {
    width: calc(100% - 100px)!important;
    height: calc(100% - 100px)!important; }
