@import "../_vars.sass";
#login {
  margin: 0 auto;
  max-width: 600px;

  label span {
    display: inline-block;
    width: 150px;
    text-align: right;
    padding: 20px; }
  input {
    display: inline-block;
    padding: 20px!important;
    border: 2px solid $tvg;
    width: 300px!important;
    line-height: 20px;
    &:active, &:focus {
      outline: none; }
    font-size: 15px;
    border-radius: 0;
    -webkit-appearance: none; }


  button.maxbtn {
    font-size: 15px;
    padding: 12px 16px;
    background-color: $tvg;
    cursor: pointer;
    margin: 10px;
    border: none;
    &:active, &:focus {
      outline: none; }
    color: $fontColor; }

  text-align: center;
  img {
    margin-top: 10px; }


  em {
    border-bottom: 1px solid $tvg;
    cursor: pointer;
    margin: 0 5px; }
  a:visited, a:link {
    color: #444; }
  a {
    text-decoration: none!important;
    font-style: italic;
    border-bottom: 1px solid $tvg; } }
