@import "../_vars.sass";
div.status {
    padding: 20px;
    margin-bottom: 10px; }


@media all and (max-width: 500px) {
    div.status {
        padding: 10px!important; } }

div.status.success, div.status.successRegistration {
    background-color: $tvg;
    color: $fontColor; }

div.status.error {
    background-color: rgba(255, 0, 0, 0.2); }

div.status.info {
    background-color: rgba(18, 152, 255, 0.2); }

div.status i.fa {
    float: right;
    cursor: pointer; }
