@import "_vars";
$navTransitionDuration: 0.3s;

$leftNavWidthOpen: 1400px;
$leftNavWidthOpenMobile: 250px;
$leftNavWidthClosed: 50px;


body {
  color: #444;
  font-family: $font !important;

  * {
    font-family: $font; } }

#mainContainer {
  a:visited, a:link {
    text-decoration: none !important;
    color: #444 !important; } }

header.MobileHeader {
  text-align: center;
  background-color: $tvg;
  color: $fontColor;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;

  line-height: 50px;
  font-size: 18px;
  //overflow-y: scroll
  z-index: 1000;
  width: 100%;

  #menuexpander {
    float: left;
    margin-top: 10px;
    padding-left: 10px;
    font-size: 33px; }

  span {
    display: block;
    margin: 0 auto;
    width: 300px;
    font-weight: 800; }

  @media all and (min-width: 500px) {
    display: none; } }

header.MobileHeaderBehind {
  @media all and (min-width: 1023px) {
    height: 70px; }
  @media all and  (max-width: 500px) {
    height: 50px; } }
//@media all and (min-width: 500px)
//display: none

header.App-header {
  width: 100%;

  text-align: left;
  background-color: $tvg;
  position: fixed;

  top: 0;
  left: 0;

  & > div {
    margin: 0 auto;
    width: $leftNavWidthOpen;
    padding: 0 7px;
    @media all and (min-width: 1023px) {
      padding: 0 20px; }
    @media all and (max-width: 1023px) {
      width: auto; } }

  //height: 60px
  //overflow: hidden
  -webkit-overflow-scrolling: touch !important;
  -ms-overflow-style: none;

  z-index: 1000;

  a {
    color: $fontColor;
    text-decoration: none;
    padding: 0px 10px;


    &:first-child {
      padding-left: 0; }
    line-height: 64px;
    height: 60px;
    margin-top: 0;
    display: inline-block;
    cursor: pointer;
    overflow: hidden;

    svg {
      margin-bottom: -2px;
      margin-right: 6px !important; }



    img {
      float: left;
      margin-top: 5px;
      background-color: white;
      border-radius: 40px;
      margin-right: 10px;
      margin-left: -100px;
      position: absolute;
      border: 2px solid $tvg; }

    transition: padding $navTransitionDuration ease-in-out;
    &:first-child {
      padding-left: 100px; }
    @media all and (max-width: 1023px) {
      &:first-child {
        padding-left: 60px; }
      img {
        margin-left: -60px;
        width: 50px; } }

    &.current {
      color: $fontColorInverse !important;
      transition: color $navTransitionDuration ease-in-out;
      color: #ffd2c7 !important; } }
  //background-color: $tvgLight!important


  & > a:not(:last-of-type) {
    border-bottom: 1px solid white; }

  em {
    display: block;
    position: absolute;

    & > a {
      height: 40px;
      line-height: 40px;
      padding-left: 30px;
      border-bottom: 1px solid white;
      border-left: 8px solid white;
      display: block; } }

  //@media all and (min-width: 500px)
  //&.open a:hover
  //padding-left: 10px
  //padding-right: 30px
  //&.closed em > a
  //padding-left: 18px

  //&.open em > a:hover
  //padding-left: 20px

  & > a:first-of-type > span {
    font-weight: 800; }

  #menuexpander {
    display: none;
    @media all and (max-width: 1023px) {
      display: block; }
    position: absolute;
    right: 10px;
    font-size: 33px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 20px; }


  transition: width $navTransitionDuration ease-in-out;

  .icon {
    margin-right: 10px; }

  @media all and (max-width: 1023px) {

    height: 100%;
    width: $leftNavWidthOpenMobile;
    a {
      display: block;
      border-bottom: 1px solid white; }
    &.closed {
      width: 50px;
      @media all and (max-width: 500px) {
        width: 0;
        display: none; }
      //width: 50px
      transition: width $navTransitionDuration ease-in-out;
      @media all and (max-width: 500px) {
        display: none; }
      //height: 60px
      //width: 100%
      //#menuexpander
      //margin-left: -5px
      //float: left
      a {
        .icon {
          margin-left: -5px; }

        text-align: center;
        &:first-child {
          padding-left: 0; }
        & > span, & > img {
          display: none; } }

      #menuexpander {
        font-size: 20px;
        margin-right: -5px;
        margin-top: 20px; }

      & > a:first-of-type {
        .icon {
          display: none; } } } } }

#mainContainer {
  @media all and (max-width: 700px) {
    overflow-x: hidden; }

  & > div {
    min-width: 250px; }


  -webkit-overflow-scrolling: touch !important; }
//overflow-y: scroll
#mainContainer, div.MyDialogRoot {
  &.navNotExistent {
    div.centercontent {
      margin: 0 auto !important; }

    margin: 0 auto;
    position: relative; }

  &.postLoad {
    transition: margin $navTransitionDuration ease-in-out; }

  &.open {
    @media all and (max-width: 1023px) and (min-width: 500px) {
      margin-left: $leftNavWidthOpenMobile; }
    @media all and (max-width: 500px) {
      width: 100%;
      margin-left: 0;
      -webkit-filter: blur(5px); }

    //#bee-plugin-container
    //width: calc(100% - 300px)

    #mainOverlayForNav {
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.2;
      position: fixed;
      z-index: 1000000;
      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        background: transparent; }
      @media all and (min-width: 500px) {
        display: none; } } }

  &.closed {
    @media all and (min-width: 500px) and (max-width: 1023px) {
      margin-left: $leftNavWidthClosed;
      width: calc(100% - 50px); }
    @media all and (max-width: 500px) {
      width: 100%;
      margin-left: 0; } }

  //, #bee-plugin-container
  //height: 100%
  //position: absolute

  @media all and (max-width: 500px) {
    height: calc(100% - 50px); }

  a {
    color: #444 !important;
    border-bottom: 1px solid $tvg !important;
    text-decoration: none !important;
    cursor: pointer !important; }

  #kalender {
    height: 100%; }

  .rbc-calendar {
    padding: 20px;
    @media all and (max-width: 500px) {
      padding: 0;
      padding-top: 10px; } }


  div.centercontent {
    margin: 0 auto;
    padding: 20px;
    width: calc(100% - 40px);
    max-width: 1400px;

    &.center {
      text-align: center; }

    &.broad {
      max-width: 1500px; }

    @media all and (max-width: 500px) {
      padding: 10px;
      width: calc(100% - 20px);
      & > div {
        padding: 0; }
      h2 {
        font-size: 20px; } } }

  & > label, ul > label {
    width: 400px;
    display: inline-block; }

  & > label span, ul > label > span, form label span {
    display: inline-block;
    width: 170px;
    vertical-align: middle;
    padding: 20px;
    padding-left: 0;
    font-weight: bold;
    @media all and (max-width: 500px) {
      display: block; } }

  label.textareaLabel > span {
    width: auto !important; }

  input, select, textarea {

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    padding: 12px 16px;
    border: 1px solid $tvg;
    margin: 1px;
    border-radius: 0;
    margin-right: 20px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 0 !important;

    &:active, &:focus {
      outline: none;
      outline: 2px solid $tvg; } }


  input {
    width: 250px; }

  input[type="checkbox"] {
    -webkit-appearance: checkbox;

    &:focus, &:hover, &:active {
      outline: none; }

    &:active {
      border-width: 0px; }
    width: auto; }

  .StripeInputElement {
    padding: 12px 16px;
    border: 1px solid $tvg; }

  textarea {
    width: 450px;
    max-width: calc(100% - 40px);
    min-height: 100px;

    &:active, &:focus {
      outline: 2px solid $tvg; } }
  //@media all and (max-width: 500px)
  //width: 200px


  span.textarea {
    display: inline-block;

    label span {
      //display: block
      width: auto; } }


  select {

    height: auto;
    background: no-repeat right url(//uid-suche.eu/src/img/dd-icon.svg);
    background-position: calc(100% - 10px) 50%;
    color: #444;
    padding: 11px 20px;
    padding-right: 30px; }

  .inputContainer {
    width: 500px;
    max-width: 100%; }

  button {
    -webkit-appearance: none; }

  .maxbtn, em.maxbtn, a.maxbtn {
    font-style: normal !important;
    padding: 12px 16px;
    background-color: $tvg;
    cursor: pointer;
    margin: 10px;
    margin-left: 0;
    border: none;
    font-size: 14px;
    font-weight: 500;
    font-family: $font;
    //color: #444
    &, a {
      color: $fontColor !important; }
    //text-transform: uppercasecapitalize
    &.mini {
      margin: 0;
      padding: 6px 12px; }

    &:active, &:focus {
      outline: none; } }

  h2 {
    margin-top: 0;

    &.headingToggleAble {
      cursor: pointer;

      svg {
        vertical-align: text-bottom; } } }

  em {
    border-bottom: 1px solid $tvg !important;
    cursor: pointer !important;
    margin: 0 5px !important;

    &.leftmost {
      margin-left: 0 !important; }

    &.normal {
      font-style: normal !important; } }

  table {
    td, th {
      padding: 8px 16px;
      @media all and (max-width: 500px) {
        padding: 4px 8px; } }

    tr:nth-child(odd) {
      background-color: rgba(3, 156, 187, 0.14) !important; }

    tr:nth-child(odd) {
      background-color: #f0f0f0; } }

  div.popup-content {
    max-width: 1200px;

    div a.close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      right: -10px;
      top: -10px;
      font-size: 24px;
      background: #fff;
      border-radius: 18px;
      line-height: 12px;
      height: 20px;
      border: 1px solid #cfcece; } }

  .sectionHeader {
    position: fixed;
    @media all and (max-width: 500px) {
      position: relative; }
    z-index: 100;
    top: 0;
    background-color: white;
    border-bottom: 4px solid $tvg;
    width: calc(100% - 340px);
    max-width: 100%;
    max-width: 1400px;
    height: 100px;
    @media all and (max-width: 500px) {
      height: auto; }

    h1 {
      font-size: 150%;
      margin-bottom: 20px;
      margin-top: 10px !important; }

    ul {
      padding: 0;

      a {
        background-color: #ddd !important;

        &.current {
          background-color: $tvg !important; } } }


    @media all and (max-width: 500px) {
      em {
        display: none !important; } } }

  //&.closed

  //.sectionHeader
  //  width: calc(100% - 90px)
  //  @media all and (max-width: 500px)
  //    width: 100%

  .sectionHeaderBackground {
    height: 150px;
    @media all and (max-width: 500px) {
      height: 30px; } }

  .sectionHeaderNav {
    @media all and (max-width: 500px) {
      a {
        display: block;
        margin-right: 0; } }

    a {
      &.current {
        color: $fontColor !important; } } }
  //overflow-y: scroll
  //overflow-x: visible
  .sectionContent > div {
    margin-top: -165px !important;
    padding-top: 165px !important;
    @media all and (max-width: 500px) {
      padding: 0; }

    &:not(:last-of-type) {
      border-bottom: 4px solid grey;
      margin-bottom: 20px !important; } } }

/* The switch - the box around the slider */
.switchWrapper {
  line-height: 50px;

  span {
    line-height: 25px; } }

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  top: 10px;
  vertical-align: text-bottom;

  input {
    opacity: 0;
    width: 0;
    height: 0; } }


/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  width: auto !important;
  padding: 0 !important; }


.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }


input:checked + .slider {
  background-color: $tvg; }


input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px); }


/* Rounded sliders */
.slider.round {
  border-radius: 34px; }


.slider.round:before {
  border-radius: 50%; }

div.react-date-picker__inputGroup input {
  padding: 0;
  border: none; }

span.react-datepicker__year-read-view--down-arrow,
span.react-datepicker__year-read-view--selected-year {
  width: auto !important;
  padding: 0 !important; }

div.react-datepicker__header__dropdown--select select {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 6px !important; }


//div.react-datepicker-wrapper
//margin-left: -4px !important

.react-datepicker__day--selected, .react-datepicker__time-list-item--selected, .react-datepicker__day--keyboard-selected {
  //.rbc-event, .rbc-event.rbc-selected,
  background-color: $tvg !important; }

.react-datepicker__day {
  &:hover {
    background-color: #ddd; } }


#nextcloud {
  position: fixed;
  right: 0;
  height: 100%; }

.open #nextcloud {
  width: calc(100% - 300px); }

//.closed #nextcloud
//width: calc(100% - 50px)

#nav-drawer {
  display: none; }


div.rt-thead.-headerGroups {
  display: none !important; }


.dateFormat {
  span:first-of-type {
    display: inline-block;
    width: 20px;
    text-align: right; }

  span:last-of-type {
    display: inline-block;
    width: 28px; } }

div.rt-resizable-header-content {
  text-align: left; }


div.react-tel-input {
  display: inline-block !important;
  width: auto;

  input {
    padding-left: 45px !important; } }

.MuiInput-underline::before {
  border: none !important; }
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none!important;
  display: none!important; }

.MuiInput-underline::after {
  margin-top: -2px;
  border-color: #444 !important; }

.MuiTextField-root {
  outline: 0 !important;
  font-family: $font !important;
  margin-top: 10px !important;

  &:hover {
    outline: 0 !important; }

  input {
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-family: $font !important; }

  .MuiInputLabel-animated {
    font-family: $font !important;
    margin-left: 26px;

    &:not(.MuiFormLabel-filled):not(.Mui-focused) {
      margin-top: 14px; }

    &.MuiFormLabel-filled, &.Mui-focused {
      color: #444; } } }

.react-datepicker-popper {
  z-index: 10; }

div.mobileBroad {
  @media all and (max-width: 500px) {
    padding: 0 !important;
    h2 {
      padding-left: 20px; } } }

#termine > table {
  min-width: 100%;
  @media all and (max-width: 500px) {
    margin-left: -10px;
    margin-right: -10px; } }

div.breakTableField {
  white-space: normal;

  .mini {
    margin-bottom: 5px !important; } }

.rv-hint {
  z-index: 10000000 !important; }

div.maxChart {
  float: left;
  @media all and (min-width: 1000px) {
    &.rightChart {
      float: right; } }

  .rv-hint {
    float: left; } }

.MsoTableGrid, .MsoNormal {
  max-width: 100%;
  margin-left: 0 !important; }

.ReactTable {
  img.gravatar {
    vertical-align: top;
    margin-right: 4px;
    margin-top: -6px;
    border-radius: 15px; }

  a img.gravatar {
    text-decoration: none; } }
//margin-bottom: -10px

div.HTMLCloudImageContainer {
  img {
    max-width: 100%;
    height: auto; } }
#footer {
  #divider {
    border-top: 1px solid $tvg;
    width: 100%;
    margin-bottom: 20px; }
  #text {
    margin-bottom: 20px;
    float: left;
    display: inline-block; }
  #images {
    float: left;
    a {
      border-bottom: none!important; }
    @media all and (min-width: 1175px) {
      float: right;
      width: 850px;
      text-align: right; }
    img {
      margin-bottom: 20px;
      height: 100px;
      width: auto;
      display: block;
      @media all and (min-width: 800px) {
        margin-left: 20px;
        height: 120px;
        width: auto;
        display: inline-block; }
      @media all and (max-width: 500px) {
        max-width: 100%;
        height: auto; } } } }

